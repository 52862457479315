<template>
    <div class="AdminList">
        <main-header menuActive="admin" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>관리자</h2>
                </div>
				<div class="row searchBtn">
					<div class="link_tab pull-left">
						<div class="InsulatorTab">
							<search-tab :searchTabData.sync="sort_data" :searchTabVal.sync="sort_key" />
						</div>
					</div>
					<div class="pull-right">
						<a class="btn10" @click="searchDetailFunc()">관리자등록</a>
					</div>
				</div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">
                                <!-- 검색키워드 -->
                                <searchConditionBox
                                        searchTitle="키워드"
                                        :searchConditionCombo.sync="searchConditionCombo"
                                        :searchKeywordText.sync="sch_keyword"
                                />
                            </div>
                        </div>
                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchFunc">조회</a>
                            <a class="btn8" @click="reset">초기화</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <colgroup>
                                <col width="5%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>NO</th>
                                <th>분류</th>
                                <th>아이디</th>
                                <th>이름</th>
                                <th>부서</th>
                                <th>연락처</th>
                                <th>등록일</th>
                                <th>시설</th>
                                <th>관리</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length>0">
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td>{{parseInt(totalCount) - ((parseInt(page)-1)*10 + (parseInt(index)) ) }}</td>
                                <td>{{ data.type }}</td>
                                <td>{{ data.id }}</td>
                                <td>{{ data.name }}</td>
                                <td>{{ data.part }}</td>
                                <td>{{ data.mobileNo }}</td>
                                <td>{{ (data.registDt != '0000-00-00 00:00:00') ? $moment(data.registDt).format('YYYY-MM-DD') : '' }}</td>
                                <td>{{ data.fasility }}</td>
                                <td>
                                    <button type="button"
                                            class="btn btn-sm btn-primary mrg-right-5"
                                            @click.stop="searchDetailFunc(data, index)"
                                    >
                                        수정
                                    </button>
                                    <button type="button"
                                            class="btn btn-sm btn-danger mrg-right-5"
                                            @click.stop="deleteConfirmFunc(index)"
                                    >
                                        삭제
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="9">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import searchConditionBox from '@/components/SearchConditionBox';
import searchTab from '@/components/SearchTab';
import Paginate from 'vuejs-paginate'

export default {
    name: 'AdminList',
    data() {
        return {
            sort_key : '',        // Tab 정보
            sort_data : [],

            searchConditionCombo : 'name',
            sch_keyword:'',

            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
        }
    },
    components: { mainHeader, mainFooter, searchConditionBox, Paginate, searchTab },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
        sort_key() {
            this.searchFunc()
        }
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.info){
            this.sch_keyword = this.$route.params.sch_keyword;
            this.sort_key = this.$route.params.sort_key;
            this.page = this.$route.params.page;
            this.searchListFunc(this.page)
        } else {
            this.reset();
        }
    },
    methods: {
		init(){
        // 여기에 파람을 만들어서 보내야 한다..
        // let params = '{"pagination":{"curPage":1,"pageScale":20},"search":{"tapkey":"","keyword":"","keyword_txt":""}}';
        // formData.append('api_name', 'patientCreateCode');
        // let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
        this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        // this.ajaxGet('nocMember/list?params=', params,'initResult');
		},
		initResult(response){
        let result = response;
        let vm = this;
        result.adminCodeList.forEach(function(obj){
          vm.sort_data.push(obj)
        });
        this.sort_data.unshift({ code:'', name:'전체' });
        this.sort_data.splice(this.sort_data.findIndex(item => item.code === 'G1106'), 1);
		},
    // 초기화
    reset(){
        this.sch_keyword = '';
        this.sort_key = '';
        this.page = 1;

        this.searchFunc();
    },
    searchFunc(){
        this.searchListFunc(1)
    },
    // 리스트 검색
    searchListFunc(pageIndex){
        // let formData = new FormData();
        // formData.append('api_name', 'adminList');       // API 이름
        // formData.append('sch_keyword', this.sch_keyword);	    // 검색 키워드
        // formData.append('sort_key', this.sort_key);	          // Tab
        // formData.append('page_num', pageIndex);	              // 현재 페이지 번호

        let params = `{"pagination":{"curPage":${pageIndex},"pageScale":20},"search":{"tapkey":"${this.sort_key}","keyword":"${this.searchConditionCombo === "0" ? "all" : this.searchConditionCombo}","keyword_txt":"${this.sch_keyword}"}}`;
        let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
        this.ajaxGet('nocMember/list?params=' + queryString, null, 'searchListFuncResult');
    },
    searchListFuncResult(response) {
        let result = response;
        this.dataList = result.list;
        if (result.total_cnt == 0){
            this.totalPage = 0;
            this.totalCount = 0;
        }else{
            this.totalPage = parseInt(result.pagination.maxPage);
            this.totalCount = parseInt(result.pagination.rstPage);
        }
    },
    // 페이지 변경
    changePage(){
        this.searchListFunc(this.page)
    },
    // 상세페이지
    searchDetailFunc(obj, index){
        let data={};
        data.sch_keyword = this.sch_keyword;
        data.sort_key = this.sort_key;
        data.info = (obj) ? obj : {};
        data.page = this.page;

        if (index !== undefined) {
            data.fidx = this.dataList[index].seq;
        } else {
            data.fidx = null;
        }

        this.$router.push({ name: 'AdminAdminDetail', params: {data} });
    },
    // 삭제 컨펀창
    deleteConfirmFunc(index){
        this.showConfirm("", "삭제 하시겠습니까?", "deleteConfirm", index);
    },
    // 삭제 처리
    deleteConfirm(index){
        let data = {
            "fIdx" : this.dataList[index].seq,
            "fAdminId" : this.storageGet('userInfo').adminId,
        }
        /*
        let formData = new FormData();
        formData.append('api_name', 'adminStateChange');
        formData.append('f_idx', f_idx);
        formData.append('f_admin_id', this.userDataAdminId);
        formData.append('f_change_code', 'DEL');
        */
        this.ajaxPost('nocMember/delete', data,'deleteConfirmResult');
    },
    // 삭제 처리 결과
    deleteConfirmResult() {
        this.showAlert('', '삭제처리 되었습니다.', "searchListFunc", 1);
    },
    }
}
</script>

<style scoped>
</style>
